import React from 'react';
import SocialMediaIcons from './SocialMediaIcons';
import Footer from './Footer';

function BusinessConnect() {
    return (
        <main className="px-5 py-14 bg-custom-orange max-md:px-5 flex flex-col items-center">
            <div className="flex flex-col items-center text-center">
                <section className="w-full max-w-4xl">
                    <div className="flex flex-col items-center">
                        <h1 className="text-4xl leading-[70px] text-slate-800 max-md:text-4xl max-md:leading-[57px]">
                            Innovate, Inspire, Achieve – Together.

                        </h1>
                        <p className="mt-2 text-base leading-6 text-slate-800">
                            Your Success is Our Mission – Let’s Build It Together.
                        </p>
                        <SocialMediaIcons />
                    </div>
                </section>
                <section className="w-full max-w-4xl mt-5">
                    <div className="flex flex-col items-center text-center text-slate-800">
                        <Footer />
                    </div>
                </section>
            </div>
        </main>
    );
}

export default BusinessConnect;
