import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const socialMediaData = [
    { icon: <FaFacebook />, href: "", alt: "Facebook" },
    { icon: <FaTwitter />, href: "", alt: "Twitter" },
    { icon: <FaLinkedin />, href: "https://www.linkedin.com/company/tech-masters-jo", alt: "LinkedIn" },
    { icon: <FaInstagram />, href: "https://www.instagram.com/tech.masters.1/", alt: "Instagram" },
];

function SocialMediaIcons() {
    return (
        <div className="flex gap-5 justify-center mt-2">
            {socialMediaData.map((iconData, index) => (
                <a
                    key={index}
                    href={iconData.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={iconData.alt}
                    className="text-2xl"
                >
                    {iconData.icon}
                </a>
            ))}
        </div>
    );
}

export default SocialMediaIcons;
