import React from "react";

function FeatureCard({ title, description }) {
    return (
        <div className="bg-black shadow-md rounded-lg p-8 text-center">  {/* Added card styles */}
            <h3 className="text-3xl bg-custom-orange-text font-medium mb-4">{title}</h3>  {/* Adjusted text size and color */}
            <p className="text-white leading-relaxed">{description}</p>
        </div>
    );
}

export default FeatureCard;