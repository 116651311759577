import React from 'react';

function HeroContent() {
    return (
        <section
            className="flex relative flex-col px-16 py-16 mt-24 mb-36 max-w-full min-h-[477px] w-[1180px] max-md:px-5 max-md:my-10 max-md:max-w-full">

            <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2db88c7163435ae9f806fc0e72f5243cd1a9cf4adfefd09da727e7b9b297a5cc?apiKey=41e382421d74423d826d7a49bbc2dfcb&&apiKey=41e382421d74423d826d7a49bbc2dfcb"
                alt="lines"
                title= "lines"
                className="object-cover absolute inset-0 size-full"
            />
            <p>
                <strong>At Tech Masters</strong>, we believe in the power of innovation
                and the impact of exceptional digital solutions. Our mission is to
                transform businesses through expert craftsmanship in web development,
                back-end systems, UI/UX design, and quality assurance. By leveraging the
                skills of our seasoned freelancers, we deliver tailored, high-quality
                services that drive success and foster growth. Trust in{" "}
                <strong>The Masters</strong> to be your dedicated partner in navigating
                the digital landscape, ensuring every project not only meets but exceeds
                expectations.
            </p>
            <p>
                <strong>Tech Masters Team</strong>
            </p>
        </section>
    );
}

export default HeroContent;