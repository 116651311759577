import React, { useRef } from 'react';
import Button from '../CommonComponents/Button';
import HeroImage from '../Assets/HeroSectionimg.jpg'; // Adjust the path according to your project structure

function HeroSection({ onContactClick }) {
    const contactRef = useRef(null);

    const scrollToSection = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.log('Contact section ref is not set  ');
        }
    };

    return (
        <section className="flex overflow-hidden relative flex-col justify-center items-center p-20 text-white min-h-[850px] max-md:px-5">
            <img
                loading="lazy"
                src={HeroImage}
                alt="HeroImage"
                title="HeroImage"
                className="object-cover absolute inset-0 size-full"
            />
            <h1 className="relative mt-36 text-8xl leading-[106px] w-[1200px] max-md:mt-10 max-md:max-w-full max-md:text-4xl max-md:leading-[49px]">
                Empowering Your Digital Future with Tech Masters
            </h1>
            <p className="relative mt-8 text-2xl leading-10 w-[600px] max-md:max-w-full">
                Premier Freelance Solutions for Web Development, Backend, QA, UI/UX Design, and More
            </p>
            {/*
            <Button
                onClick={() => {
                    scrollToSection();
                    onContactClick(); // Ensure that this function is called if needed
                }}
                text="Contact Us Today"
                className="relative self-start px-6 py-4 mt-8 ml-56 text-sm font-medium tracking-wider leading-5 text-center bg-orange-300 rounded-[100px] text-slate-800 max-md:px-5 max-md:ml-2.5"
            />
             */}
        </section>
    );
}

export default HeroSection;
