import React, { useRef } from 'react';
import { Stack } from 'react-bootstrap';
import Header from '../HeaderNavigatoin/Header';
import HeroSection from '../HeroSection/HeroSection';
import Separator from '../CommonComponents/Separator';
import WhatWeDoSection from '../WhatWeDoSection/WhatWeDoSection';
import WhyTechHeroes from '../WhyTechHeroes/WhyTechHeroes';
import TechMastersHero from '../TechMastersHero/TechMastersHero';
import BusinessConnect from '../FooterNavigation/BusinessConnect';
import FloatingContactButton from '../CommonComponents/FloatingContactButton';
import OurClientSection from "../ourClients/OurClientSection";

function HomePage() {
    const homeRef = useRef(null);
    const servicesRef = useRef(null);
    const aboutUsRef = useRef(null);
    const contactRef = useRef(null);
    const clientRef = useRef(null);


    const scrollToSection = (section) => {
        switch (section) {
            case 'home':
                homeRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'services':
                servicesRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'about-us':
                aboutUsRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'contact':
                contactRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'our-clients':
                clientRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Header onNavItemClick={scrollToSection} />
            <Stack>
                <div ref={homeRef}>
                    <HeroSection onContactClick={() => scrollToSection('contact')} />
                </div>
                <Separator />
                <div ref={servicesRef}>
                    <WhatWeDoSection />
                </div>
                <Separator />
                <div ref={aboutUsRef}>
                    <WhyTechHeroes />
                </div>
                <Separator />
                <div ref={clientRef}>
                    <OurClientSection />
                </div>
                <Separator />
                <div className="hidden md:block">
                    <TechMastersHero />
                </div>
                <Separator />
                <div >
                    <BusinessConnect />
                </div>
            </Stack>
            <FloatingContactButton />
        </div>
    );
}

export default HomePage;
