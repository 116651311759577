import React from 'react';
import Logo from './Logo';
import Navigation from './Navigation';

function Header({ onNavItemClick }) {
    return (
        <header className="flex justify-center items-center px-16 py-5 bg-custom-orange text-slate-800 max-md:px-5">
            <div className="flex gap-5 justify-between max-w-full w-[559px] max-md:flex-wrap">
                <Logo />
                <Navigation onNavItemClick={onNavItemClick} />
            </div>
        </header>
    );
}

export default Header;
