import React from "react";
import ClientsCard from "./ClientsCard";
import GreenGas from '../Assets/GreenGas.png'; // Adjust the path according to your project structure
import Kaleela from '../Assets/kaleelah.svg'; // Adjust the path according to your project structure
import { FaHourglassHalf } from 'react-icons/fa'; // Import the icon from react-icons

const services = [
    {
        icon: GreenGas,
        title: "Green Gas JO",
        description: "A Market Leader in the Kingdom of Jordan in LPG & Sustainable Energy Fields." ,
        type: "Web and Backend Development",  // Service type
        link: "https://www.greengasjo.com/" // Replace with the actual link
    },
    {
        icon: Kaleela,
        title: "Kaleela App",
        description: "Kaleela is an Arabic mobile app and website designed to fit the needs of any native and non-native speaker who wishes to learn Arabic online. Available for both Android and iOS, the app comes in a variety of languages: English, Spanish, Italian, French, Portuguese, Romanian, Turkish, Korean, Indonesian and Chinese.",
        type: "Quality Assurance / Testing",  // Service type
        link: "https://kaleela.com/en" // Replace with the actual link
    },
    {
        icon: <FaHourglassHalf />,  // You can add an appropriate icon or a "Coming Soon" placeholder
        title: "More Clients Coming Soon",
        description: "Exciting new projects are on the way. Stay tuned for more collaborations!",
        type: "Future Projects",  // You can customize this as needed
        link: "#" // No link needed for this placeholder
    }

];

function OurClientSection() {
    return (
        <section className="p-20 text-white bg-black max-md:px-5">
            <h2 className="text-4xl font-bold mb-10 text-center">The Masters Clients</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 text-2xl leading-10">
                {services.map((service, index) => (
                    <ClientsCard key={index} {...service} />
                ))}
            </div>
        </section>
    );
}

export default OurClientSection;
