import React, { useState, useEffect } from 'react';

function Footer() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const updateYear = () => {
            setCurrentYear(new Date().getFullYear());
        };

        // Update the year on component mount and every year change
        updateYear();
        const intervalId = setInterval(updateYear, 60 * 60 * 24 * 1000); // Update every 24 hours

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <footer className="text-center text-slate-800 mt-10">
            <div className="text-slate-800 font-extrabold leading-7">
                © {currentYear} Tech Masters. All rights reserved. Designed by Tech Masters.
            </div>
        </footer>
    );
}

export default Footer;
