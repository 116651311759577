import React from "react";

function ServiceCard({ icon, title, description }) {
    return (
        <div className="flex flex-col items-center p-5 bg-black rounded-lg shadow-lg">
            <div className="text-4xl mb-3 text-white">{icon}</div> {/* Centered icon */}
            <h3 className="text-xl bg-custom-orange-text font-bold mb-3 text-center text-white">{title}</h3> {/* Centered title */}
            <p className="text-lg text-center">{description}</p> {/* Centered description */}
        </div>
    );
}

export default ServiceCard;
