import './App.css';

import HomePage from "./components/HomePage/HomePage";
import React from "react";

function App() {

    return (
        <div className="App">
      <HomePage/>
        </div>
    );
}

export default App;
