import React from "react";

function ClientsCard({ icon, title, description, type, link }) {
    return (
        <div className="flex flex-col items-center">
            {typeof icon === "string" ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <img src={icon} alt={`${title} icon`} title={title} className="w-30 h-20 mb-4" />
                </a>
            ) : (
                <div className="text-6xl mb-4">{icon}</div>
            )}
            <h3 className="text-3xl font-semibold">{title}</h3>
             {/*  <p className="text-lg underline">{type}</p> */}
            <p className="text-sm" >{description}</p>
        </div>
    );
}

export default ClientsCard;
