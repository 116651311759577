import React from "react";
import ServiceCard from "./ServiceCard";
import { FaPaintBrush, FaServer, FaCode, FaBusinessTime, FaVial, FaBug , FaHeadset,FaDatabase } from 'react-icons/fa';

const services = [
    {
        icon: <FaPaintBrush />, // UI/UX Design icon
        title: "UI/UX Design",
        description: "Designing intuitive and engaging user interfaces and experiences to ensure your users have a seamless and enjoyable interaction with your product.",
        alignment: "end"
    },
    {
        icon: <FaServer />, // Back-end Development icon
        title: "Back-end Development",
        description: "Building robust and scalable back-end systems to power your applications.",
        alignment: "center"
    },
    {
        icon: <FaCode />, // Web Development icon
        title: "Web Development",
        description: "Crafting visually stunning and highly functional websites tailored to your needs.",
        alignment: "start"
    },
    {
        icon: <FaBusinessTime />, // Product/Business Enhancements icon
        title: "Product/Business Enhancements",
        description: "Enhancing your product or business with innovative solutions to increase efficiency and profitability.",
        alignment: "start"
    },
    {
        icon: <FaVial />, // Automated Testing Scripts icon
        title: "Automated Testing Scripts",
        description: "Creating efficient automated testing scripts to streamline your development process and enhance reliability.",
        alignment: "end"
    },
    {
        icon: <FaBug  />, // Quality Assurance icon
        title: "Quality Assurance",
        description: "Ensuring the highest quality through testing and meticulous quality assurance practices.",
        alignment: "center"
    },


    {
        icon: <FaDatabase />,
        title: "Data Engineering",
        description: "Designing and building systems for collecting, storing, and analyzing data to support business decisions.",
        alignment: "center"
    },

    {
        icon: <FaHeadset />,
        title: "Technical Support",
        description: "Providing expert technical support to ensure your systems are running smoothly and efficiently.",
        alignment: "center"
    }
];

function WhatWeDoSection() {
    return (
        <section className="p-20 text-white bg-black max-md:px-5">
            <h2 className="text-4xl font-bold mb-10 text-center">Masters Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 text-2xl leading-10">
                {services.map((service, index) => (
                    <ServiceCard key={index} {...service} />
                ))}
            </div>
        </section>
    );
}

export default WhatWeDoSection;
