import React from 'react';

function Header({ onNavItemClick }) {
    const navItems = ['Home', 'Services', 'About Us',"Our Clients" ];
    //    const navItems = ['Home', 'Services', 'About Us', 'Contact'];

    if (typeof onNavItemClick !== 'function') {
        console.error('onNavItemClick is not a function');
        return null;
    }

    return (
        <nav className="flex gap-5 justify-between py-3 text-sm font-medium tracking-wider leading-5">
            {navItems.map((item, index) => (
                <button
                    key={index}
                    onClick={() => onNavItemClick(item.toLowerCase().replace(' ', '-'))}
                    className="focus:outline-none focus:underline"
                >
                    {item}
                </button>
            ))}
        </nav>
    );
}

export default Header;
