import React from "react";
import FeatureCard from "./FeatureCard";

const features = [
    {
        title: "Expert Freelancers",
        description: "Our team consists of seasoned professionals with extensive experience in web development, backend solutions, UI/UX design, quality assurance, and more.",
    },
    {
        title: "Tailored Solutions",
        description: "We customize our services to meet your specific needs and goals, ensuring a personalized approach to every project.",
    },
    {
        title: "Commitment to Quality",
        description: "We prioritize delivering top-quality results and ensuring client satisfaction through rigorous quality checks and continuous improvement.",
    },
    {
        title: "Innovative Approach",
        description: "We leverage the latest technologies and methodologies to provide cutting-edge solutions that keep you ahead in a competitive market.",
    },
    {
        title: "Holistic Services",
        description: "From captivating UI/UX designs to robust backend systems, our comprehensive range of services ensures all your digital needs are met under one roof.",
    },
    {
        title: "Client-Centric Focus",
        description: "We listen to your needs, communicate transparently, and work collaboratively to achieve the best outcomes for your projects.",
    },
];

function WhyTechHeroes() {
    return (
        <section className="flex justify-center items-center py-20 bg-black">  {/* Center alignment */}
            <div className="max-w-7xl px-5">
                <h2 className="text-6xl text-white leading-[70.2px] font-bold">Why Tech
                    Masters?</h2>  {/* Added bold font */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-20"> {/* Changed to grid layout */}
                    {features.map((feature, index) => (
                        <FeatureCard key={index} title={feature.title} description={feature.description}/>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default WhyTechHeroes;