import React from 'react';
import HeroContent from './HeroContent';

function TechMastersHero() {
    return (
        <header className="flex flex-col justify-center text-3xl leading-10 text-center text-white ">
            <div  className="flex relative flex-col px-20 py-20     "
                  style={{
                      backgroundImage: `url('https://imagizer.imageshack.com/img923/5149/DP0MFR.jpg')`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                  }}
            >
                <div
                    className="flex justify-center items-center px-16 py-20 w-full bg-black bg-opacity-50 max-md:px-5 max-md:max-w-full">
                    <HeroContent/>
                </div>
            </div>
        </header>
    );
}

export default TechMastersHero;