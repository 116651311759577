import React from 'react';

function Logo() {
    return (
        <div className="flex flex-col justify-center my-auto text-2xl font-extrabold leading-6 whitespace-nowrap">
            <div className="flex gap-2 justify-center p-1.5">
                <div className="shrink-0 self-start aspect-[1.49] w-[33px] font-bold">
                    <img src="/TechMasterLogoBlack.svg" alt="Tech Masters Logo" className="w-8 h-8 animate-pulse" title="Tech Masters Logo" />
                </div>
                <div>Tech-Masters</div>
            </div>
        </div>
    );
}

export default Logo;
